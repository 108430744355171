@import url('https://fonts.googleapis.com/css2?family=Work Sans&display=swap');

#root {
  font-family: 'Work Sans';
  font-weight: 500;
  /* --yellow: rgb(250, 231, 168);  */
  /* --yellow: #FFF1CE;  */
  --yellow: rgb(255, 255, 162);
}
.mainContainer {
  margin-top: 10px;
  margin-bottom: 10px;
}
.rowElement {
  padding: 10px;
  margin: 0px;
}
.header {
  margin-top: 40px;
  margin-bottom: 40px;
}
.logo {
  position: absolute;
  left: 15px;
}
.topnav {
  position: absolute;
  right: 15px;
  color: black;
}
.tagline {
  text-align: center;
  color: white;
}
.signature {
  /* text-align: right !important; */
  padding: 30px;
  padding-left: 200px;
}
.nav {
  color: white
}
.hidden { 
  display:none; 
}
.priority {
  padding-bottom: 20px;
  padding: 0px;
  margin-bottom: 20px;  
  background-color: var(--yellow);
}
.page {
  padding-bottom: 20px;
  padding: 0px;
  background-color: white;
  margin-bottom: 20px;  
}
.step {
  margin-bottom: 20px;
  padding: 0px;
  background-color: white;
}
.disabled {
  color: gray;
}
Button {
  padding-left: 20px;
  padding-top: 11px;
}
h6 {
  /* padding-left: 20px; */
  padding-top: 11px;
  font-size: revert;
  font-weight: bold;
  /* font-family: 'Work Sans'; */
}
.tab-content {
  margin: 15px 25px 15px 25px;
  width: 95%;
  text-align: left;
}
.nav-tabs {
  margin-left: 15px;
}
ul {
  text-align: left;
}

#bg {
  height: 100vh;
  overflow-y: scroll;
}
a.navbar-brand {
  font-weight: 600;
}
h3 {
  font-weight: 800 !important;
  font-size: x-large !important;
}
button.btn.btn-nope {
  background-color: #b787e2;
  border-color: white !important;
  color: white;
}
button.btn.btn-nope:hover {
  background-color: #9861c9;
  border-color: white !important;
  color: white;
}
button.btn.btn-nope:active {
  background-color: #7238a5;
  border-color: white !important;
  color: white;
}
button.btn.btn-yeah {
  background-color: #5fd161;
  border-color: white !important;
  color: white;
}
button.btn.btn-yeah:hover {
  background-color: #48bd4a;
  border-color: white !important;
  color: white;
}
button.btn.btn-yeah:active {
  background-color: #2ca02e;
  border-color: white !important;
  color: white;
}
button.btn.btn-continue {
  background-color: #6ed6eb;
  border-color: white !important;
  color: white;
}
button.btn.btn-continue:hover {
  background-color: #40afc5;
  border-color: white !important;
  color: white;
}
button.btn.btn-continue:active {
  background-color: #248699;
  border-color: white !important;
  color: white;
}
a.nav-link {
  font-size: small;
  font-weight: 600;
  color:white;
}
.backBtn {
  font-size: small !important;
  font-weight: 600 !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs {
  color: #495057;
  background-color: var(--yellow)  !important;
  border-color: #b8b8b8 #b8b8b8 #b8b8b8  !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #495057;
  background-color: var(--yellow) !important;
  border-color: #b8b8b8 #b8b8b8 var(--yellow) !important;
}
.nav-tabs .nav-link:focus {
  color: #495057;
  border-color: #b8b8b8 #b8b8b8 var(--yellow) !important;
}
.nav-tabs .nav-link:hover {
  color: #495057;
  border-color: #b8b8b8 #b8b8b8 var(--yellow) !important;
}
.toastText {
  color: black;
}
.tooltiptext {
  /* text-decoration-line: underline; */
  /* text-decoration-style: dotted; */
  color: black;
  background-color: #ffbfe3 !important;
  /* text-decoration-style: double; */
  /* text-decoration-style: dashed; */
  /* text-decoration-color: green; */
}
a:hover {
  text-decoration: none;
  color: black;
}
a#uncontrolled-tab-example-tab-why {
  color: black;
}
a#uncontrolled-tab-example-tab-what {
  color: black;
}
a#uncontrolled-tab-example-tab-how {
  color: black;
}
a#uncontrolled-tab-example-tab-where {
  color: black;
}
